"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectAutoPlay = exports.toogleAutoPlay = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const conversationSlice = (0, toolkit_1.createSlice)({
    name: 'conversation',
    initialState: {
        autoPlay: false,
    },
    reducers: {
        toogleAutoPlay: (state) => {
            state.autoPlay = !state.autoPlay;
        },
    },
});
exports.default = conversationSlice.reducer;
exports.toogleAutoPlay = conversationSlice.actions.toogleAutoPlay;
const selectAutoPlay = (state) => state.conversation.autoPlay;
exports.selectAutoPlay = selectAutoPlay;
