"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIsAudioLoading = exports.selectIsImageLoading = exports.selectIsLoading = exports.selectDocumentShareState = exports.selectIsPdfOpen = exports.selectIsDocumentConversationOpen = exports.selectDocumentUploadComplete = exports.selectNote = exports.selectDocumentJson = exports.selectDocumentStreaming = exports.selectDocumentImageUrl = exports.selectDocumentSummary = void 0;
const selectDocumentSummary = (state) => state.documents.summary;
exports.selectDocumentSummary = selectDocumentSummary;
const selectDocumentImageUrl = (state) => state.documents.imageUrl;
exports.selectDocumentImageUrl = selectDocumentImageUrl;
const selectDocumentStreaming = (state) => state.documents.streaming;
exports.selectDocumentStreaming = selectDocumentStreaming;
const selectDocumentJson = (state) => state.documents.documentJson;
exports.selectDocumentJson = selectDocumentJson;
const selectNote = (state) => state.documents.note;
exports.selectNote = selectNote;
const selectDocumentUploadComplete = (state) => state.documents.documentUploadComplete;
exports.selectDocumentUploadComplete = selectDocumentUploadComplete;
const selectIsDocumentConversationOpen = (state) => (state.documents.isConversationOpen);
exports.selectIsDocumentConversationOpen = selectIsDocumentConversationOpen;
const selectIsPdfOpen = (state) => state.documents.isPdfOpen;
exports.selectIsPdfOpen = selectIsPdfOpen;
const selectDocumentShareState = (state) => state.documents.shareState;
exports.selectDocumentShareState = selectDocumentShareState;
const selectIsLoading = (state) => state.documents.isLoading;
exports.selectIsLoading = selectIsLoading;
const selectIsImageLoading = (state) => state.documents.isImageRegenerating;
exports.selectIsImageLoading = selectIsImageLoading;
const selectIsAudioLoading = (state) => state.documents.isAudioGenerating;
exports.selectIsAudioLoading = selectIsAudioLoading;
