"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabNavigator = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_native_1 = require("react-native");
const react_native_fontawesome_1 = require("@fortawesome/react-native-fontawesome");
const AppText_1 = require("../AppText");
const colors_1 = require("../../common/colors");
const TabNavigator = ({ tabs, activeTab, onTabChange, style, tabStyle, activeTabStyle, textStyle, activeTextStyle, isMobileScreen = false, }) => {
    const [hoveredTab, setHoveredTab] = (0, react_1.useState)(null);
    const indicatorAnim = (0, react_1.useRef)(new react_native_1.Animated.Value(0)).current;
    const [tabWidth, setTabWidth] = (0, react_1.useState)(0);
    const getIndicatorOffset = (0, react_1.useCallback)((tabIndex) => (tabIndex * tabWidth), [tabWidth]);
    const moveIndicator = (0, react_1.useCallback)((newPosition) => {
        react_native_1.Animated.timing(indicatorAnim, {
            toValue: newPosition,
            duration: 300,
            useNativeDriver: true,
        }).start();
    }, []);
    (0, react_1.useEffect)(() => {
        const activeIndex = tabs.findIndex(tab => tab.id === activeTab);
        moveIndicator(getIndicatorOffset(activeIndex));
    }, [activeTab, tabs, getIndicatorOffset, moveIndicator]);
    const handleTabLayout = (0, react_1.useCallback)(({ nativeEvent: { layout: { width } } }) => {
        setTabWidth(width);
    }, []);
    const handleTabPress = (tabId, disabled) => {
        if (!disabled) {
            onTabChange(tabId);
        }
    };
    return ((0, jsx_runtime_1.jsxs)(react_native_1.View, { style: [styles.container, style], children: [tabs.map((tab, index) => {
                const isActive = activeTab === tab.id;
                const isDisabled = tab.disabled;
                const isHovered = hoveredTab === tab.id;
                return ((0, jsx_runtime_1.jsx)(react_native_1.Pressable, { onPress: () => handleTabPress(tab.id, tab.disabled), onHoverIn: () => setHoveredTab(tab.id), onHoverOut: () => setHoveredTab(null), onLayout: handleTabLayout, style: [
                        styles.tab,
                        tabStyle,
                        isActive && activeTabStyle,
                        isDisabled && styles.disabledTab,
                    ], disabled: isDisabled, accessible: true, accessibilityRole: "tab", accessibilityState: { selected: isActive, disabled: isDisabled }, children: (0, jsx_runtime_1.jsxs)(react_native_1.View, { style: { flexDirection: 'row', alignItems: 'center' }, children: [tab.icon && ((0, jsx_runtime_1.jsx)(react_native_fontawesome_1.FontAwesomeIcon, { icon: tab.icon, color: isActive ? colors_1.Colors.PRIMARY_BLUE : isHovered ? colors_1.Colors.white : colors_1.Colors.DARK_TEXT, size: 16, style: styles.icon })), (0, jsx_runtime_1.jsx)(AppText_1.AppText, { style: [
                                    styles.tabText,
                                    textStyle,
                                    isActive && activeTextStyle,
                                ], color: isActive ? colors_1.Colors.PRIMARY_BLUE : isHovered ? colors_1.Colors.white : colors_1.Colors.DARK_TEXT, children: tab.label })] }) }, tab.id));
            }), (0, jsx_runtime_1.jsx)(react_native_1.Animated.View, { style: [
                    styles.indicator,
                    {
                        width: `${100 / tabs.length}%`,
                        transform: [
                            {
                                translateX: indicatorAnim,
                            },
                        ],
                    },
                ] })] }));
};
exports.TabNavigator = TabNavigator;
const styles = react_native_1.StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: colors_1.Colors.DARK_500,
        marginBottom: 20,
    },
    tab: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 10,
    },
    disabledTab: {
        opacity: 0.5,
    },
    icon: {
        marginRight: 8,
    },
    tabText: {
        fontSize: 16,
        fontWeight: '500',
    },
    indicator: {
        position: 'absolute',
        bottom: 0,
        height: 2,
        backgroundColor: colors_1.Colors.PRIMARY_BLUE,
    },
});
