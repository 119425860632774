"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.audioContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
;
;
exports.audioContext = (0, react_1.createContext)({
    audioCache: new Map(),
});
const Provider = exports.audioContext.Provider;
function AudioProvider({ children }) {
    const audioCache = (0, react_1.useMemo)(() => (new Map()), []);
    return ((0, jsx_runtime_1.jsx)(Provider, { value: { audioCache }, children: children }));
}
exports.default = AudioProvider;
;
