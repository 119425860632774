"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMarkdownBlock = exports.getMarkdownContent = exports.getSummaryContent = exports.getFiveMinuteWrapContent = exports.normalizeSummary = void 0;
const wrapQuotationsRegex = /^"|"$/g;
const extraStarRegex = /\*{3,}/g;
function getSectionRegex(sectionName) {
    return new RegExp(`^#{3}\\s${sectionName}\\s*\\n.*\\n{0,2}?`, 'm');
}
;
function getSectionTitleRegex(sectionName) {
    return new RegExp(`^#{3}\\s${sectionName}\\s*\\n`, 'm');
}
;
function getSectionContentRegex(sectionName) {
    return new RegExp(`(?<=^#{3}\\s${sectionName}\\s*\\n{1,2}).*$`, 'gm');
}
;
function getSectionBlockRegex(sectionName) {
    return new RegExp(`^#{3}\\s${sectionName}\\s*\\n{1,2}(.*(?=\\n{2}#{3})|.*$)`, 'gms');
}
function getContentBlockRegex(sectionName) {
    return new RegExp(`(?<=^#{3}\\s${sectionName}\\s*\\n{1,2})(.*?(?=\\n{1,2}#{3})|.*$)`, 'ms');
}
function normalizeMarkdown(markdown) {
    return (markdown
        .replaceAll(wrapQuotationsRegex, '')
        .replaceAll(extraStarRegex, '**')
        .replaceAll('\\n', '\n')
        .replaceAll('\\"', '\"'));
}
;
function normalizeSummary(summary) {
    const imagePromptRegex = /.*(?=### Summary)/s;
    const tinySummaryRegex = getSectionRegex('Tiny Summary');
    const summaryTitleRegex = getSectionTitleRegex('Summary');
    const coreInformationTitleRegex = getSectionTitleRegex('Core Information');
    const fiveMinutesWrap = getSectionBlockRegex('Five Minute Wrap');
    const normalizedMarkdown = normalizeMarkdown(summary);
    const extraSummary = normalizedMarkdown.match(getSectionBlockRegex('Summary'))?.[1];
    const normalizedSummary = (normalizedMarkdown
        .replace(extraSummary ?? '', '')
        .replace(imagePromptRegex, '')
        .replace(tinySummaryRegex, '')
        .replace(summaryTitleRegex, '')
        .replace(coreInformationTitleRegex, '')
        .replace(fiveMinutesWrap, ''));
    return normalizedSummary === summary ? '' : normalizedSummary;
}
exports.normalizeSummary = normalizeSummary;
function getFiveMinuteWrapContent(summary) {
    const fiveMinutesContent = getContentBlockRegex('Five Minute Wrap');
    const match = normalizeMarkdown(summary).match(fiveMinutesContent);
    const result = match ? match[0].trim() : '';
    return result;
}
exports.getFiveMinuteWrapContent = getFiveMinuteWrapContent;
function getSummaryContent(summary, sectionName) {
    const matchArray = normalizeMarkdown(summary).match(getSectionContentRegex(sectionName));
    return matchArray?.[matchArray?.length - 1];
}
exports.getSummaryContent = getSummaryContent;
;
function getMarkdownContent(markdown, sectionName) {
    const matchArray = normalizeMarkdown(markdown).match(getSectionContentRegex(sectionName));
    return matchArray?.[matchArray?.length - 1];
}
exports.getMarkdownContent = getMarkdownContent;
;
function getMarkdownBlock(markdown, sectionName) {
    return normalizeMarkdown(markdown).match(getContentBlockRegex(sectionName))?.[0];
}
exports.getMarkdownBlock = getMarkdownBlock;
;
