"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetStreaming = exports.resetNote = exports.resetDocumentJson = exports.resetDocumentImageUrl = exports.resetDocumentSummary = exports.closeDocumentPanels = exports.togglePdfOpen = exports.toggleDocumentConversationOpen = exports.patchDocumentJson = exports.setIsConversationOpen = exports.setIsAudioGenerating = exports.setIsImageRegenerating = exports.setIsLoading = exports.setDocumentSharingName = exports.setDocumentSharingType = exports.setIsDocumentSharing = exports.setDocumentShareState = exports.setDocumentUploadComplete = exports.setDocumentStreaming = exports.setNote = exports.setDocumentJson = exports.setDocumentImageUrl = exports.setDocumentSummary = exports.shareDocument = exports.documentSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const document_1 = require("../services/document");
const initialState = {
    isConversationOpen: false,
    isPdfOpen: false,
    isLoading: false,
    isImageRegenerating: false,
    shareState: {
        isSharing: false,
        shareUrl: "",
    },
};
exports.documentSlice = (0, toolkit_1.createSlice)({
    name: 'document',
    initialState,
    reducers: {
        setIsConversationOpen(state, { payload }) {
            state.isConversationOpen = payload;
        },
        setDocumentSummary(state, { payload }) {
            state.summary = payload;
        },
        setDocumentImageUrl(state, { payload }) {
            state.imageUrl = payload;
        },
        setDocumentJson(state, { payload }) {
            state.documentJson = payload;
        },
        setNote(state, { payload }) {
            state.note = payload;
        },
        setDocumentStreaming(state, { payload }) {
            state.streaming = payload;
        },
        setDocumentUploadComplete(state, { payload }) {
            state.documentUploadComplete = payload;
        },
        setDocumentShareState(state, { payload }) {
            state.shareState = payload;
        },
        setIsDocumentSharing(state, { payload }) {
            state.shareState.isSharing = payload;
        },
        setDocumentSharingType(state, { payload }) {
            state.shareState.sharingType = payload;
        },
        setDocumentSharingName(state, { payload }) {
            state.shareState.documentName = payload;
        },
        setIsLoading(state, { payload }) {
            state.isLoading = payload;
        },
        setIsImageRegenerating(state, { payload }) {
            state.isImageRegenerating = payload;
        },
        setIsAudioGenerating(state, { payload }) {
            state.isAudioGenerating = payload;
        },
        patchDocumentJson(state, { payload }) {
            state.documentJson = state.documentJson && { ...state.documentJson, ...payload };
        },
        toggleDocumentConversationOpen(state) {
            state.isConversationOpen = !state.isConversationOpen;
            state.isPdfOpen = false;
        },
        togglePdfOpen(state) {
            state.isPdfOpen = !state.isPdfOpen;
            state.isConversationOpen = false;
        },
        closeDocumentPanels(state) {
            state.isConversationOpen = false;
            state.isPdfOpen = false;
        },
        resetDocumentSummary(state) {
            state.summary = initialState.summary;
        },
        resetDocumentImageUrl(state) {
            state.imageUrl = initialState.imageUrl;
        },
        resetDocumentJson(state) {
            state.documentJson = initialState.documentJson;
        },
        resetNote(state) {
            state.note = initialState.note;
        },
        resetStreaming(state) {
            state.streaming = initialState.streaming;
        },
        setWebUrl(state, { payload }) {
            state.webUrl = payload;
        },
    },
});
exports.shareDocument = (0, toolkit_1.createAsyncThunk)('document/shareDocument', async ({ conversationId, document, password }, { dispatch }) => {
    const shareLink = await (0, document_1.getShareLink)(conversationId, 'fileId' in document
        ? document.fileId
        : document.id, password);
    if (shareLink instanceof Error) {
        throw shareLink;
    }
    ;
    dispatch((0, exports.setDocumentShareState)({
        isSharing: true,
        shareUrl: shareLink.url,
        sharedConversationId: conversationId,
        sharedFileId: 'fileId' in document ? document.fileId : document.id,
        sharingType: 'owner',
        documentName: 'title' in document ? document.title : document.fileName,
        isPasswordSet: shareLink.isPasswordSet === 'Y',
    }));
});
_a = exports.documentSlice.actions, exports.setDocumentSummary = _a.setDocumentSummary, exports.setDocumentImageUrl = _a.setDocumentImageUrl, exports.setDocumentJson = _a.setDocumentJson, exports.setNote = _a.setNote, exports.setDocumentStreaming = _a.setDocumentStreaming, exports.setDocumentUploadComplete = _a.setDocumentUploadComplete, exports.setDocumentShareState = _a.setDocumentShareState, exports.setIsDocumentSharing = _a.setIsDocumentSharing, exports.setDocumentSharingType = _a.setDocumentSharingType, exports.setDocumentSharingName = _a.setDocumentSharingName, exports.setIsLoading = _a.setIsLoading, exports.setIsImageRegenerating = _a.setIsImageRegenerating, exports.setIsAudioGenerating = _a.setIsAudioGenerating, exports.setIsConversationOpen = _a.setIsConversationOpen, exports.patchDocumentJson = _a.patchDocumentJson, exports.toggleDocumentConversationOpen = _a.toggleDocumentConversationOpen, exports.togglePdfOpen = _a.togglePdfOpen, exports.closeDocumentPanels = _a.closeDocumentPanels, exports.resetDocumentSummary = _a.resetDocumentSummary, exports.resetDocumentImageUrl = _a.resetDocumentImageUrl, exports.resetDocumentJson = _a.resetDocumentJson, exports.resetNote = _a.resetNote, exports.resetStreaming = _a.resetStreaming;
exports.default = exports.documentSlice;
