"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const colors_1 = require("../../../../common/colors");
exports.styles = react_native_1.StyleSheet.create({
    modalOverlay: {
        flex: 1,
        backgroundColor: `${colors_1.Colors.DARK_700}CC`,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: colors_1.Colors.DARK_600,
        flexGrow: 0,
        padding: 20,
        borderRadius: 16,
        width: '85%',
        maxWidth: 428,
    },
    languageSelectContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
        position: 'absolute',
        bottom: 10,
    },
    webContainer: {
        maxWidth: 528,
    },
    mobileContainer: {
        width: '95%',
        borderRadius: 16,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    headerText: {
        color: colors_1.Colors.white,
        fontSize: 24,
        fontWeight: 'bold',
    },
    closeButton: {
        padding: 5,
    },
    tabContainer: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    tab: {
        flex: 1,
        paddingVertical: 10,
        alignItems: 'center',
        borderBottomWidth: 2,
        borderColor: colors_1.Colors.DARK_500,
    },
    activeTab: {
        borderColor: colors_1.Colors.PRIMARY_300,
    },
    tabText: {
        color: colors_1.Colors.DARK_TEXT,
        fontSize: 16,
        textAlign: 'center',
    },
    tabTextMobile: {
        fontSize: 14,
        lineHeight: 18,
    },
    tabTextDesktop: {
        fontSize: 16,
        lineHeight: 24,
    },
    activeTabText: {
        color: colors_1.Colors.PRIMARY_300,
    },
    inputContainer: {
        marginBottom: 20,
        gap: 2,
    },
    contentContainer: {
        flex: 1,
        marginBottom: 0,
        maxHeight: 256,
    },
    label: {
        color: colors_1.Colors.DARK_TEXT,
        marginBottom: 5,
        fontSize: 16,
    },
    input: {
        backgroundColor: colors_1.Colors.DARK_700,
        borderWidth: 0,
        color: colors_1.Colors.white,
        padding: 12,
        borderRadius: 8,
        fontSize: 16,
        maxHeight: 54,
    },
    contentInput: {
        height: '100%',
        textAlignVertical: 'top',
        padding: 12,
        borderRadius: 8,
        fontSize: 16,
        color: colors_1.Colors.white,
        borderColor: colors_1.Colors.DARK_500,
        backgroundColor: colors_1.Colors.DARK_700,
        marginBottom: 20,
    },
    uploadContainer: {
        borderWidth: 1,
        borderColor: colors_1.Colors.PRIMARY_300,
        backgroundColor: colors_1.Colors.PRIMARY_700,
        borderStyle: 'dashed',
        borderRadius: 8,
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
        minHeight: 308,
    },
    uploadText: {
        color: colors_1.Colors.PRIMARY_300,
        marginTop: 10,
        fontSize: 16,
    },
    uploadSubtext: {
        marginTop: 4,
        fontSize: 14,
        textAlign: 'center',
    },
    disabledButton: {
        opacity: 0.5,
    },
});
